(function () {
  const logos = document.querySelectorAll('.oxitgen-logo-animated');

  logos.forEach(l => {
    const logoPaths = l.querySelectorAll('path');

    var tl = gsap.timeline({repeat: -1});

    for (let index = 0; index < logoPaths.length; index++) {

      tl.to(logoPaths[index], 
        {
          scale: 0.90,
          duration: 1.2,
          ease: "power1.out",
          transformOrigin:"50%",
        },
        '-=0.5'
      );

    }

    for (let index = 0; index < logoPaths.length; index++) {

      tl.to(logoPaths[index], 
        {
          scale: 1.0,
          duration: 1.2,
          ease: "power1.out",
          transformOrigin:"50%",
        },
        '-=0.5'
      );

    }

    tl.resume();

  });


})();
