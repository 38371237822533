(function () {
    let contact_person = document.querySelector('.contact-person-wrapper');
    if(contact_person != null){
        let closeButton = contact_person.querySelector('.contact-person-close-button');
        closeButton.addEventListener("click", function(e){ 
            if(!contact_person.classList.contains('closed')){
                contact_person.classList.toggle('closed');
                console.log("FOO");
            }
        });
        
        let mainButton = contact_person.querySelector('.contact-person-button');
        mainButton.addEventListener("click", function(e){ 
            if(contact_person.classList.contains('closed')){
                contact_person.classList.toggle('closed');
                console.log("bar");
            }
        });
    }
})();
  