// Get current URL and Highlight after Jump
function jumpToHighlight(target) {
  const url = new URL(target.href);
  let anchor = url.hash ? url.hash.substring(1) : null;
  if(!document.getElementById(anchor)){
    return;
  }
  highlightAnimation(document.getElementById(anchor).parentElement);

  return;
}

// Highlight animation on an Element
function highlightAnimation(element) {
    /*
  var tl = gsap.timeline({});
  tl.to(element, {
    duration: 0.2,
    backgroundColor: "rgb(6, 181, 217 / 0.2)",
    ease: "power1.out",
  });
  tl.to(element, {
    duration: 1.0,
    backgroundColor: "transparent",
    ease: "power1.out",
  });

  gsap.to(element, {
    duration: 0.2,
    backgroundColor: "rgb(6, 181, 217 / 0.2)",
    ease: "power1.out",
  });
  */
}

// Highlight animation after scroll down after anschor jump
function animateOnScrollEndAnchorJump(callback, delay = 150) {
  let isScrolling;
  let scrollListenerAdded = false;

  function checkScroll() {
    clearTimeout(isScrolling);
    isScrolling = setTimeout(() => {
      callback();
      window.removeEventListener("scroll", checkScroll);
      scrollListenerAdded = false;
    }, delay);
  }

  window.addEventListener("scroll", checkScroll, { passive: true });

  window.addEventListener("hashchange", () => {
    if (!scrollListenerAdded) {
      window.addEventListener("scroll", checkScroll, { passive: true });
      scrollListenerAdded = true;
    }

    requestAnimationFrame(() => {
      checkScroll();
    });
  });

  if (window.location.hash) {
    requestAnimationFrame(() => {
      checkScroll();
    });
  }
}
