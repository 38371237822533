let loadingBar = document.createElement("div");
loadingBar.classList.add("loading_bar-bar");
loadingWrapper.appendChild(loadingBar);

let loadingBargreen = document.createElement("div");
loadingBargreen.classList.add("loading_bar-bar_green");
loadingBar.appendChild(loadingBargreen);

let loadingBarlightgreen = document.createElement("div");
loadingBarlightgreen.classList.add("loading_bar-bar_lightgreen");
loadingBar.appendChild(loadingBarlightgreen);

let loadingBarblue = document.createElement("div");
loadingBarblue.classList.add("loading_bar-bar_blue");
loadingBar.appendChild(loadingBarblue);

let loadingBarpurple = document.createElement("div");
loadingBarpurple.classList.add("loading_bar-bar_purple");
loadingBar.appendChild(loadingBarpurple);

let loadingBarred = document.createElement("div");
loadingBarred.classList.add("loading_bar-bar_red");
loadingBar.appendChild(loadingBarred);

let loadingBarProgress = document.createElement("div");
loadingBarProgress.classList.add("loading_bar-progress");
loadingWrapper.appendChild(loadingBarProgress);



let loadingDuration = 1.0;

if (!(document.referrer && document.referrer.includes(window.location.hostname))) {
    window.addEventListener('popstate', function(event) {
        console.log('Interne Navigation: Back/Forward-Taste verwendet.');
    });

    var loadingAnim = gsap.from(loadingBar, {
        duration: loadingDuration,
        width: "0",
        ease: "power1.out",
        onComplete: function(){ 
            gsap.to(loadingWrapper, {
                duration: loadingDuration,
                opacity: "0",
                ease: "power1.out",
                onComplete: function(){ 
                    loadingWrapper.remove();
                },
            });
        },
    });




    function updateProgress(){
        loadingBarProgress.innerHTML = Number(100 * loadingAnim.progress()).toFixed(0) + " %";
        if(loadingAnim.progress() < 1.0){
            requestAnimationFrame(updateProgress);
        }
    }

    requestAnimationFrame(updateProgress);
}