(function () {
  let footerHeight = document.querySelector('footer').offsetHeight;

  document.body.style.marginBottom = footerHeight + "px";

  gsap.from(".socials", {
    delay: footerDelay,
    duration: 3.5,
    width: "50px",
    ease: "elastic.out(0.5,0.4)",
  });



})();
