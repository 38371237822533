(function () {
  const animDuration = 1;

  let buttons = document.querySelectorAll(".job-button");

  buttons.forEach((element) => {
    
    var color = getComputedStyle(document.documentElement).getPropertyValue('--'+element.getAttribute("icon-color"));

    let iconAnim = gsap.to(
      element.querySelectorAll(".job-button-icon-wrapper svg"),
      {
        duration: animDuration,
        rotation: 0,
        backgroundColor: color,
        ease: "power1.out",
        paused: true,
        transformOrigin: "50% 50%",
      }
    );

    let iconWrapperAnim = gsap.to(
      element.querySelectorAll(".job-button-icon-wrapper"),
      {
        duration: animDuration,
        left: "calc(100% - var(--space-sm) - var(--icon-md))",
        ease: "power1.out",
        paused: true,
        transformOrigin: "50% 50%",
      }
    );

    let titleAnim = gsap.to(
      element.querySelectorAll(".job-button-title-wrapper"),
      {
        duration: animDuration,
        marginLeft: 0,
        ease: "power1.out",
        paused: true,
      }
    );

    let regionAnim = gsap.to(
      element.querySelectorAll(".job-button-region-wrapper"),
      {
        duration: animDuration,
        marginRight: "40px",
        ease: "power1.out",
        paused: true,
      }
    );

    element.addEventListener("mouseenter", function () {
      iconAnim.play();
      titleAnim.play();
      regionAnim.play();
      iconWrapperAnim.play();
    });
    element.addEventListener("mouseleave", function () {
      iconAnim.reverse();
      titleAnim.reverse();
      regionAnim.reverse();
      iconWrapperAnim.reverse();
    });
  });
})();
