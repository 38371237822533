// File#: _1_filter-navigation
// Usage: codyhouse.co/license
(function() {
    var FilterNav = function(element) {
      this.element = element;
      this.wrapper = this.element.getElementsByClassName('js-filter-nav__wrapper')[0];
      this.nav = this.element.getElementsByClassName('js-filter-nav__nav')[0];
      this.list = this.nav.getElementsByClassName('js-filter-nav__list')[0];
      this.modalClose = this.element.getElementsByClassName('js-filter-nav__close-btn')[0];
      this.marker = this.element.getElementsByClassName('js-filter-nav__marker');
      this.layout = 'expanded';
      initFilterNav(this);
    };

    // Custom Jump To Animation
    window.addEventListener("load", (event) => {
        animateOnScrollEndAnchorJump(() => {
            jumpToHighlight(window.location);
        });
    });
  
    function initFilterNav(element) {
      checkLayout(element); // init layout
      if(element.layout == 'expanded') placeMarker(element);
      element.element.addEventListener('update-layout', function(event){ // on resize - modify layout
        checkLayout(element);
      });
  
      // update selected item
      element.wrapper.addEventListener('click', function(event){
        var newItem = event.target.closest('.js-filter-nav__btn');
        if(newItem) {
          updateCurrentItem(element, newItem);
          return;
        }
      });
  
    };
  
    function updateCurrentItem(element, btn) {
      if(btn.getAttribute('aria-current') == 'true') {
        return;
      }
      var activeBtn = element.wrapper.querySelector('[aria-current]');
      if(activeBtn) activeBtn.removeAttribute('aria-current');
      btn.setAttribute('aria-current', 'true');
      if(element.layout == 'expanded') placeMarker(element);
    };
  
    function checkLayout(element) {
      element.layout = 'expanded';
      element.element.classList.add('filter-nav--expanded');
      element.element.classList.remove('filter-nav--collapsed');
      element.modalClose.classList.add('is-hidden');
    
      // place background element
      if(element.layout == 'expanded') placeMarker(element);
    };
  
    function placeMarker(element) {
      var activeElement = element.wrapper.querySelector('.js-filter-nav__btn[aria-current="true"]');
      if(element.marker.length == 0 || !activeElement ) return;
      element.marker[0].style.width = activeElement.offsetWidth+'px';
      element.marker[0].style.height = activeElement.offsetHeight+'px';
      element.marker[0].style.transform = 'translate('+
        (activeElement.getBoundingClientRect().left - element.list.getBoundingClientRect().left)+'px,'+
        (activeElement.getBoundingClientRect().top - element.list.getBoundingClientRect().top)+'px)';
    };
  
    var filterNav = document.getElementsByClassName('js-filter-nav');
    if(filterNav.length > 0) {
      var filterNavArray = [];
      for(var i = 0; i < filterNav.length; i++) {
        filterNavArray.push(new FilterNav(filterNav[i]));
      }
  
      var resizingId = false,
        customEvent = new CustomEvent('update-layout');
  
      window.addEventListener('resize', function() {
        clearTimeout(resizingId);
        resizingId = setTimeout(doneResizing, 100);
      });
  
      // wait for font to be loaded
      if(document.fonts) {
        document.fonts.onloadingdone = function (fontFaceSetEvent) {
          doneResizing();
        };
      }
  
      function doneResizing() {
        for( var i = 0; i < filterNavArray.length; i++) {
          (function(i){filterNavArray[i].element.dispatchEvent(customEvent)})(i);
        };
      };
    }
  }());
  
function onClickChangeMarker(target){
    let marker_color_bg = target.getAttribute('data-color-bg');
    let marker_color_text = target.getAttribute('data-color-text');

    var color_bg = getComputedStyle(document.documentElement).getPropertyValue("--"+marker_color_bg);
    var color_text = getComputedStyle(document.documentElement).getPropertyValue("--"+marker_color_text);

    let marker = document.querySelectorAll(".js-filter-nav__marker");

    gsap.set(document.querySelectorAll(".js-filter-nav__btn"), {
      clearProps: "all",
    });

    gsap.to(marker, {
        duration: 0.5,
        backgroundColor: color_bg,
        ease: "power1.out",
    });

    gsap.to(target, {
        duration: 0.5,
        color: color_text,
        ease: "power1.out",
    });

}