(function () {
    // Selektiere den Container
    const container = document.querySelector('.location-groups');
    if (container) {
        const elementsWithIDs = container.querySelectorAll('[id]');

        
        elementsWithIDs.forEach(element => {
            // Füge einen Klick-Event-Listener hinzu
            element.addEventListener('mouseenter', function() {
                // Hole die ID des geklickten Elements
                const id = this.id;

                // Selektiere alle Elemente mit der Klasse, die der ID entspricht
                const elementsWithSameClass = document.querySelectorAll('.' + id);

                // Führe eine Aktion für alle gefundenen Elemente aus (z.B. füge eine Klasse hinzu)
                elementsWithSameClass.forEach(el => {
                    el.classList.add('highlight');
                });
            });

            element.addEventListener('mouseleave', function() {
                // Hole die ID des geklickten Elements
                const id = this.id;

                // Selektiere alle Elemente mit der Klasse, die der ID entspricht
                const elementsWithSameClass = document.querySelectorAll('.' + id);

                // Führe eine Aktion für alle gefundenen Elemente aus (z.B. füge eine Klasse hinzu)
                elementsWithSameClass.forEach(el => {
                    el.classList.remove('highlight');
                });
            });
        });
    }
}());