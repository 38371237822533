(function () {
  defaultTitle = document.title;
  leaveTitle = document.querySelector('title').getAttribute('leave-title');
  enterTitle = document.querySelector('title').getAttribute('enter-title');
  intervalTime = document.querySelector('title').getAttribute('interval');

  if(leaveTitle == "") leaveTitle = defaultTitle;
  if(enterTitle == "") enterTitle = defaultTitle;
  if(intervalTime == "") intervalTime = 3000;

  intervalMode = false;
  intervalCallback = '';

  document.addEventListener("mouseleave", (event) => {
    document.title = leaveTitle;
    clearInterval(intervalCallback);
  });


  document.addEventListener("mouseenter", (event) => {
    document.title = enterTitle;
    clearInterval(intervalCallback);
    intervalCallback = setInterval(function () {
      if (intervalMode) {
        document.title = defaultTitle;
      } else {
        document.title = enterTitle;
      }
      intervalMode = !intervalMode;
    }, intervalTime);
  });


})();
