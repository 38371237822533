(function () {
  let buttons = document.querySelectorAll(".job-button");
  let animationDur = 0.5;
  let modalWindowsAnimations = [];
  let vh = Math.max(
    document.documentElement.clientHeight || 0,
    window.innerHeight || 0
  );
  let overlayEle = document.getElementById("modal-overlay");
  let modalCloseButton = document.getElementById("modal-close-button");

  let closeBtnAnim = gsap.to(modalCloseButton, {
    duration: 0.1,
    paused: true,
    scale: 1.1,
  });
  modalCloseButton.addEventListener("mouseenter", () => closeBtnAnim.play());
  modalCloseButton.addEventListener("mouseleave", () => closeBtnAnim.reverse());

  let overlayAnim = gsap.to(overlayEle, {
    duration: animationDur,
    opacity: 0.83,
    ease: "power3.out",
    paused: true,
  });

  function closeAllModalWindows() {
    const activeApplyButton = document.querySelectorAll(".apply-button-active");
    activeApplyButton.forEach((e) => {
      e.classList.remove("apply-button-active");
    });

    overlayAnim.reverse();
    modalWindowsAnimations.forEach((element) => {
      element.reverse();
    });
    overlayEle.classList.toggle("modal-overlay-closed");
    modalCloseButton.classList.toggle("modal-overlay-closed");
  }

  function moveJob(target, delta, initialTop) {
    const style = window.getComputedStyle(target);
    const matrix = new DOMMatrixReadOnly(style.transform);

    var newPos = matrix.m42 + delta;

    var maxScroll = -(target.offsetHeight + initialTop - vh);
    if (newPos <= maxScroll) {
      newPos = maxScroll;
    }
    if (newPos > 0) {
      newPos = 0;
    }
    /*
    console.log("ELHGT: " + (target.offsetHeight));
    console.log("WHGT : " + (vh));
    console.log("ITop : " + (initialTop));
    console.log("cPos : " + (matrix.m42));
    console.log("nPos : " + (newPos));
    console.log("Max  : " + (maxScroll));
    */

    var curJobID = target.id.replace("job-modal-", '');
    var curColor = document.querySelector('[job-id="'+ curJobID +'"]');

    var colorHex = getComputedStyle(document.documentElement).getPropertyValue('--'+curColor.getAttribute("icon-color"));
    if (newPos >= -90) {
      gsap.to(modalCloseButton, {
        backgroundColor: "white",
        color: "black"
      });
    } else {
      gsap.to(modalCloseButton, {
        backgroundColor: colorHex,
        color: "white"
      });
    }

    gsap.to(target, {
      duration: 0.2,
      y: newPos,
      ease: "power1.out",
    });
  }

  function modalMoveScroll(element) {
    let initialTop = element.getBoundingClientRect().top;

    element.addEventListener(
      "touchstart",
      function (event) {
        event.preventDefault();
        event.currentTarget.dataset.touchstart = event.touches[0].pageY;
      },
      false
    );

    element.addEventListener(
      "touchmove",
      function (event) {
        event.preventDefault();
        var delta =
          event.currentTarget.dataset.touchstart - event.touches[0].pageY;
        moveJob(event.currentTarget, -delta * 4, initialTop);
        event.currentTarget.dataset.touchstart = event.touches[0].pageY;
      },
      false
    );

    element.addEventListener("wheel", function (event) {
      event.preventDefault();
      var delta = -event.deltaY;
      moveJob(event.currentTarget, delta, initialTop);
    });
    addEventListener("resize", (event) => {
      vh = Math.max(
        document.documentElement.clientHeight || 0,
        window.innerHeight || 0
      );
    });
  }

  overlayEle.addEventListener("click", function () {
    closeAllModalWindows();
  });

  modalCloseButton.addEventListener("click", function () {
    closeAllModalWindows();
  });

  buttons.forEach((element) => {
    var jobID = element.getAttribute("job-id");
    var jobModalWindow = document.getElementById("job-modal-" + jobID);

    var mobileApplyButton = document.querySelector(
      '[job-id-apply-btn="' + jobID + '"]'
    );

    modalMoveScroll(jobModalWindow);

    let modalWinAnim = gsap.to(jobModalWindow, {
      duration: animationDur,
      x: 0,
      ease: "power3.out",
      paused: true,
    });
    modalWindowsAnimations.push(modalWinAnim);

    element.addEventListener("click", function () {
      if (overlayEle.classList.contains("modal-overlay-closed")) {
        overlayAnim.play();
        modalWinAnim.play();
        if (mobileApplyButton != null) {
          mobileApplyButton.classList.add("apply-button-active");
        }
      } else {
        overlayAnim.reverse();
        modalWinAnim.reverse();
      }

      overlayEle.classList.toggle("modal-overlay-closed");
      modalCloseButton.classList.toggle("modal-overlay-closed");
    });
  });
})();
