
gsap.from('.smooth-gradient-feature', 
{
    duration: 340, 
    rotation: 360,
    ease: "power1.out",
    repeat: -1,
    paused: false,
    yoyo: true,
});

/*
gsap.from('path', 
{
    duration: 3, 
    x: "random(-5, 5, 5)",
    y: "random(-5, 5, 3)",
    ease: "power1.out",
    repeat: -1,
    paused: false,
    yoyo: true,
});
*/